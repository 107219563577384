<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>产品管理</el-breadcrumb-item>
      <el-breadcrumb-item>优惠券管理</el-breadcrumb-item>
      <el-breadcrumb-item>优惠券详情</el-breadcrumb-item>
    </el-breadcrumb>
    <el-page-header @back="goBack"></el-page-header>
    <!-- 优惠券操作 -->
    <el-card>
      <el-row class="title_row">
        <el-col>
          <span>优惠券操作</span>
        </el-col>
      </el-row>
      <el-row :gutter="0">
        <el-col :span="3">
          <el-button
            type="primary"
            @click="showChangeStateDialog()"
            >修改状态</el-button
          >
        </el-col>
        <el-col :span="3">
          <el-button
            type="primary"
            @click="issueCouponsVisible = true"
            >发放优惠券</el-button
          >
        </el-col>
      </el-row>
    </el-card>
    <el-card style="margin-top: 15px">
      <el-row class="title_row">
        <el-col :span="12">
          <div class="list_title">优惠券信息</div>
        </el-col>
      </el-row>
      <el-row :gutter="20" class="el-row">
        <el-col :span="6">
          <span class="exam_span_class">优惠券名称: {{ couponInfo.name }}</span>
        </el-col>
        <el-col :span="6">
          <span class="exam_span_class">价格: ￥{{ couponInfo.price }}</span>
        </el-col>
        <el-col :span="6">
          <span class="exam_span_class">数量: {{ couponInfo.surplusNum }}</span>
        </el-col>
        <el-col :span="6">
          <span class="exam_span_class">编码: {{ couponInfo.code }}</span>
        </el-col>
      </el-row>
      <el-row :gutter="20" class="el-row" style="margin-top: 10px">
        <el-col :span="6">
          <span class="exam_span_class">开始时间: {{ couponInfo.beginTime }}</span>
        </el-col>
        <el-col :span="6">
          <span class="exam_span_class">结束时间: {{ couponInfo.endTime }}</span>
        </el-col>
        <el-col :span="6">
          <span class="exam_span_class">启用状态: <template>
            <el-tag
              type="danger"
              v-if="couponInfo.status === false"
              size="mini"
              >未启用</el-tag
            >
            <el-tag
              type="success"
              v-else-if="couponInfo.status === true"
              size="mini"
              >已启用</el-tag
            >
            <span v-else>-</span>
          </template></span>
        </el-col>
        <el-col :span="6">
          <span class="exam_span_class">会员专享:
          <template>
            <el-tag
              type="danger"
              v-if="couponInfo.vipBuy === false"
              size="mini"
              >否</el-tag
            >
            <el-tag
              type="success"
              v-else-if="couponInfo.vipBuy === true"
              size="mini"
              >是</el-tag
            >
            <span v-else>-</span>
          </template>
          </span>
        </el-col>
      </el-row>
      <!-- <el-descriptions border>
        <el-descriptions-item label="优惠券名称">{{
          couponInfo.name
        }}</el-descriptions-item>
        <el-descriptions-item label="价格"
          >￥{{ couponInfo.price }}</el-descriptions-item
        >
        <el-descriptions-item label="数量">{{
          couponInfo.surplusNum
        }}</el-descriptions-item>
        <el-descriptions-item label="编码">{{
          couponInfo.code
        }}</el-descriptions-item>
        <el-descriptions-item label="开始时间">{{
          couponInfo.beginTime
        }}</el-descriptions-item>
        <el-descriptions-item label="结束时间">{{
          couponInfo.endTime
        }}</el-descriptions-item>
        <el-descriptions-item label="启用状态">
          <template>
            <el-tag
              type="danger"
              v-if="couponInfo.status === false"
              size="mini"
              >未启用</el-tag
            >
            <el-tag
              type="success"
              v-else-if="couponInfo.status === true"
              size="mini"
              >已启用</el-tag
            >
            <span v-else>-</span>
          </template></el-descriptions-item
        >
        <el-descriptions-item label="会员专享">
          <template>
            <el-tag
              type="danger"
              v-if="couponInfo.vipBuy === false"
              size="mini"
              >否</el-tag
            >
            <el-tag
              type="success"
              v-else-if="couponInfo.vipBuy === true"
              size="mini"
              >是</el-tag
            >
            <span v-else>-</span>
          </template></el-descriptions-item
        >
        <el-descriptions-item label="创建时间">{{
          couponInfo.createTime
        }}</el-descriptions-item>
      </el-descriptions> -->
    </el-card>
    <el-card class="list_card" style="margin-top: 20px">
      <el-row class="title_row">
        <el-col :span="12">
          <div class="list_title">包含产品信息</div>
        </el-col>
      </el-row>
      <el-table
        header-cell-class-name="tableHeaderStyle"
        :data="couponInfo.products"
        border
        stripe
      >
        <el-table-column label="序号" type="index"></el-table-column>
        <el-table-column label="产品名称" prop="name"></el-table-column>
      </el-table>
    </el-card>
        <!--已发放用户列表 -->
        <el-card class="list_card" style="margin-top: 20px">
      <el-row class="title_row">
        <el-col :span="12">
          <div class="list_title">用户列表</div>
        </el-col>
      </el-row>
      <el-table
        header-cell-class-name="tableHeaderStyle"
        :data="dataList"
        border
        stripe
      >
        <el-table-column label="#" type="index"></el-table-column>
        <el-table-column label="手机号" prop="phone"></el-table-column>
        <el-table-column label="姓名" prop="name"></el-table-column>
        <el-table-column label="性别" prop="gender" :formatter="genderFormatter"></el-table-column>
        <el-table-column label="省市区" prop="provence">
           <template scope="scope">
            <span>{{scope.row.provinceName}}/{{scope.row.cityName}}/{{scope.row.countyName}}</span>
          </template>
        </el-table-column>
        <el-table-column label="学校" prop="schoolName"></el-table-column>
        <el-table-column label="年级" prop="gradeName"></el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryForm.pageNum"
        :page-sizes="[10, 20, 40, 80]"
        :page-size="queryForm.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>
        <!-- 修改优惠券使用状态Dialog -->
        <el-dialog
      title="修改使用状态"
      :visible.sync="changeUsedDialogVisible"
      width="30%"
    >
      <el-form
        :model="changeSatetForm"
        ref="changeSatetFormRef"
        :rules="changeSatetFormRules"
        label-width="140px"
      >
        <el-form-item label="使用状态" prop="statusIn">
          <el-select
            placeholder="请选择"
            v-model="changeSatetForm.statusIn"
            clearable
          >
            <el-option
              v-for="item in dict_used"
              :key="item.dictCode"
              :label="item.dictLabel"
              :value="item.dictValue"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="changeUsedDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="changeUsed()">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 优惠券发放dialog -->
    <el-dialog
      title="优惠券发放"
      :visible.sync="issueCouponsVisible"
      width="35%"
      @close="issueCouponsClose"
    >
      <el-form
        :model="issueCouponsForm"
        label-width="140px"
        :rules="issueCouponsFormRules"
        ref="issueCouponsFormRef"
      >
        <el-form-item
          label="VIP专享"
          prop="spbcnUserVipStatus"
          label-width="140px"
        >
          <el-radio-group v-model="issueCouponsForm.spbcnUserVipStatus">
            <el-radio :label="true">是</el-radio>
            <el-radio :label="false" :disabled="couponInfo.vipBuy">否</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="小程序账号" prop="phonesStr" v-if="issueCouponsForm.spbcnUserVipStatus === false">
          <el-input
            type="textarea"
            placeholder="请输入,批量开通账号请以“英文逗号”分隔"
            v-model="issueCouponsForm.phonesStr"
          >
          </el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="issueCouponsVisible = false">取 消</el-button>
        <el-button type="primary" @click="issueCouponsClicked">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getCouponDetail, updateCouponStatus, couponIssue, getfindUserCoupon } from '@/http/api'
export default {
  data () {
    return {
      total: 0,
      dataList: [],
      queryForm: {
        pageNum: 1,
        pageSize: 10
      },
      issueCouponsVisible: false,
      issueCouponsForm: {
        phonesStr: '',
        spbcnUserVipStatus: null
      },
      couponId: this.$route.query.couponId,
      couponInfo: {},
      changeUsedDialogVisible: false,
      issueCouponsFormRules: {
        spbcnUserVipStatus: [{ required: true, message: '请选择', trigger: 'change' }]
      },
      changeSatetFormRules: {
        statusIn: [{ required: true, message: '请选择', trigger: 'change' }]
      },
      changeSatetForm: {
        statusIn: null
      },
      dict_used: [
        {
          dictCode: 0,
          dictLabel: '未启用',
          dictValue: 0
        },
        {
          dictCode: 1,
          dictLabel: '已启用',
          dictValue: 1
        }
      ]
    }
  },
  created () {
    this.getTheCouponInfo()
    this.getDataList()
  },
  methods: {
    goBack () {
      this.$router.back()
    },
    // 显示修改状态弹窗
    showChangeStateDialog () {
      this.changeSatetForm.id = this.couponId
      this.changeSatetForm.statusIn = this.couponInfo.status === true ? 1 : 0
      this.changeUsedDialogVisible = true
    },
    // 提交状态修改
    async changeUsed () {
      this.changeSatetForm.status = this.changeSatetForm.statusIn !== 0
      updateCouponStatus(this.changeSatetForm).then((res) => {
        this.$message.success('修改成功')
        this.changeUsedDialogVisible = false
        this.getTheCouponInfo()
      }).catch((err) => {
        console.log('err', err)
      })
    },
    // 获取优惠券详情
    getTheCouponInfo () {
      getCouponDetail(this.couponId).then((res) => {
        this.couponInfo = res.data
        this.issueCouponsForm.spbcnUserVipStatus = this.couponInfo.vipBuy
      }).catch((err) => {
        console.log('err', err)
      })
    },
    handleSizeChange (newSize) {
      this.queryForm.pageSize = newSize
      this.getDataList()
    },
    handleCurrentChange (newCurPage) {
      this.queryForm.pageNum = newCurPage
      this.getDataList()
    },
    async getDataList () {
      getfindUserCoupon(this.changeSatetForm).then((res) => {
        this.dataList = res.data.list
        this.total = res.data.total
      }).catch((err) => {
        console.log('err', err)
      })
    },
    // 发放优惠券
    issueCouponsClicked () {
      this.$refs.issueCouponsFormRef.validate(async valid => {
        if (!valid) return
        this.issueCouponsForm.couponId = this.couponId
        if (this.issueCouponsForm.phonesStr !== '') {
          this.issueCouponsForm.phones = this.issueCouponsForm.phonesStr.split(',')
        }
        couponIssue(this.issueCouponsForm).then((res) => {
          this.$message.success('发放成功')
          this.issueCouponsVisible = false
          this.getDataList()
        }).catch((err) => {
          console.log('err', err)
        })
      })
    },
    genderFormatter (data) {
      if (data.gender === 0) {
        return '女'
      }
      return '男'
    },
    issueCouponsClose () {
      this.$refs.issueCouponsFormRef.resetFields()
    }
  }
}
</script>

<style lang="less" scoped>
.exam_span_class {
  font-size: 14px;
}
</style>
